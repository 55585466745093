/* Hodl */
#Hodls {
    padding-top:60px;
    margin: 0 auto;
    min-height: 100vh;
}

#Hodls h2 {
    padding:20px;
    font-size:3rem;
    text-shadow: 0px 2px 5px black;
}

#Hodls .hodls-container {
    margin: 0 auto;
    padding:20px;
    max-width:1000px;
    width:100%;
    text-align: center;
}

#Hodls .nft-filters-container {
    overflow-x:scroll;
    display: flex;
}

#Hodls .nft-filters-container::-webkit-scrollbar {
    display: none;
}

#Hodls .nft-filters-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#Hodls .nft-filters {
    display: flex;
    justify-content: center;
    padding:5px;
    margin:0px auto;
}

#Hodls .nft-filters button {
    border:0px;
    padding:5px;
    border-radius: 5px;
    margin:5px;
    flex:1;
    max-width:60px;
    min-width:60px;
    background: transparent;
    color:lightgray;
    cursor: pointer;
    font-weight: bold;
}

#Hodls .nft-filters button.active {
    background:rgb(62, 62, 62);
}

#Hodls .nfts {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content:center;
    margin:0px;
    cursor: pointer;
}

#Hodls .nft {
    display: inline-block;
    flex:1;
    margin:6px;
    padding:0px;
    box-shadow:0px 2px 5px rgb(14, 14, 14);
    background: #252525;
    text-align: center;
    max-width: 300px;
    min-width:300px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

#Hodls .nft-img-container {
    display: block;
    overflow: hidden;
}

#Hodls .nft-img {
    display: block;
    width: 300px;
    height:300px;
    transition:all ease .4s;
    position: relative;
    max-height: 100%;
    object-fit: contain;
}

#Hodls .nft-img-container:hover .nft-img {
    transform: scale(1.1);
}

#Hodls .nft p {
    padding: 10px;
    font-size: 0.9rem;
    font-weight:bold;
    position:absolute;
    z-index: 9;
    bottom:0px;
    /* background:rgba(0, 0, 0, 0.599); */
    background: linear-gradient(0deg,#101010b0 60%,#3c3c3c00);
    width: 100%;
}

#Hodls .nft:hover p {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
}
#Hodls .nft p {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

/* Modal */
#modal {
    position: fixed;
    background:rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

#close-modal {
    position: fixed;
    top:30px;
    right:30px;
    z-index: 1;
    cursor: pointer;
    font-size: 2rem;
}

#modal .nft-modal {
    position: fixed;
    background:#292929;
    overflow-y: auto;
    width:100%;
    height:100vh;
    max-height: calc(100vh - 50px);
    max-width: 800px;
    text-align: center;
    box-shadow: 0px 0px 15px rgb(0, 0, 0);
    border-radius: 10px;
}

#modal img.nft-img {
    width:100%;
}

#modal .nft-title {
    font-size: 1.5rem;
    font-weight: bold;
}

#modal .nft-modal .links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    margin:4px auto;
    font-size: 1.2rem;
    text-align: center;
    width:200px;
}

#modal .nft-modal .links a {
    display: flex;
    margin:10px;
    padding:0px;
}

#modal .nft-modal .links svg {
    width:40px;
    height:40px;
}

#modal .nft-metadata {
    padding:10px;
}

#modal .nft-metadata h3 {
    padding:10px;
    border:2px solid gray;
    margin: 0px auto;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px black;
    max-width: 600px;
    font-size: 2rem;
}

#modal .nft-attributes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#modal .nft-attribute {
    color:rgb(220, 220, 220);
    padding:5px;
    margin:4px;
    font-size: 1.2rem;
    border:2px solid gray;
    text-align: center;
    width:200px;
    box-shadow: 0px 2px 5px black;
    border-radius: 10px;
}

#modal .nft-attribute-type {
    display: block;
    width:100%;
    font-weight:bold;
}

#modal .nft-attribute-value {
    display: block;
}

#modal .nft-attributes span {
    padding:2px;
    display: inline-block;
    margin:0px;
}