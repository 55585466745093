footer {
    background:rgb(14, 14, 14);
    padding:20px;
    text-align: center;
    color:lightgray;
    z-index: 3;
    position:relative;
}

footer h3 {
    margin-bottom:5px;
    font-size: 1.2rem;
}

footer a {
    text-decoration: none;
    display: inline-block;
    margin-bottom:5px;
    color:lightgray;
}

footer img {
    width: 150px;
    padding:10px;
}

footer p {
    margin:0px;
    padding: 10px;
}

footer .link_list {
    padding:10px;
    display: inline-block;
    text-align: left;
    vertical-align: top;
}

footer .link_list a {
    display: block;
}