/* showcase */
#Lair {
    padding-top:60px;
    margin: 0 auto;
    min-height: 100vh;
}

#Lair h2 {
    padding:20px;
    font-size:3rem;
    text-shadow: 0px 2px 5px black;
    text-align: center;
}

#Lair .lair-container {
    margin: 0 auto;
    padding:20px;
    max-width:900px;
    width:100%;
}

#Lair article {
    margin-bottom:10px;
    background: rgb(20, 20, 20);
    border:2px solid #a6a6a6;
    margin-bottom:20px;
    box-shadow: 0px 2px 15px rgb(9, 9, 9);
    border-radius: 10px;
    overflow: hidden;
}

#Lair article img {
    max-width: 40px;
    border-radius: 50%;
}

#Lair h3 {
    padding:10px;
    background:#a6a6a6;
    color:rgb(26, 26, 26);
    font-weight: bold;
    font-size: 1.6rem;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

#Lair h3 a {
    background:#a6a6a6;
    color:rgb(26, 26, 26);
    font-weight:normal;
    font-size: 1rem;
    text-decoration: none;
}

#Lair h3 img {
    width:40px;
    height:40px;
}

#Lair .data-container {
    padding:0px;
    display: flex;
    flex-wrap: wrap;
}

#Lair .data {
    color: #a6a6a6;
    border:2px solid #a6a6a6;
    padding:0px;
    flex:1;
    margin:10px;
    display: flex;
    min-width:300px;
    max-width: 400px;
}

#Lair .data span {
    display: inline-block;
}

#Lair .data .title {
    padding:10px;
    background:#a6a6a6;
    color:rgb(26, 26, 26);
    font-weight: bold;
    min-width: 160px;
}

#Lair .data .value {
    padding:10px;
    font-weight: bold;
}

#Lair .data-table {
    border: 2px solid #a6a6a6;
    flex:4;
    margin:10px;
    width:100%;
}

#Lair .table-container {
    width:100%;
}

#Lair .table-header {
    background:#a6a6a6;
    padding:5px;
    color:rgb(26, 26, 26);
    font-weight: bold;
}

#Lair table {
    margin:0px;
    width:100%;
    color: #a6a6a6;
}

#Lair table a {
    color: #a6a6a6;
    text-decoration: none;
    cursor: pointer;
}

#Lair th,
#Lair td {
  border: 1px solid #a6a6a6;
  overflow: hidden;
  text-align: left;
  padding:5px;
}

#Lair .data-footer {
    padding:10px;
    color:rgb(170, 170, 170);
    font-size: 1rem;
    padding-top:0px;
}