#Game {
    padding-top:60px;
    margin: 0 auto;
    min-height: 100vh;
}

#Game h2 {
    padding:20px;
    font-size:3rem;
    text-shadow: 0px 2px 5px black;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Game h2 svg {
    margin-left: 10px;
    cursor: pointer;
}

#Game .game-container {
    margin: 0 auto;
    padding:20px;
    max-width:1000px;
    width:100%;
    text-align: center;
}

/* */
#Game .games-header {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

#Game .games-header-network-error {
    display: flex;
    margin: 0px auto;
    color:#d20000;
    fill:#d20000;
    background: rgb(14,14,14);
    text-align: center;
    border-radius: 10px;
    border:1px solid #740000;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: 500px;
}

#Game .games-header-network-error a {
    color:#d20000;
    font-weight: bold;
}

#Game .games-header-network-error svg {
    display: block;
    height:20px;
    margin-left: 5px;
    min-width:20px;
}

/* token exchange */
#Game .token-exchange {
    flex:1;
    border:1px solid #5d23d17f;
    border-radius: 5px;
    background: rgb(14,14,14);
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
}

#Game .token-exchange form {
    display: flex;
    flex:1;
    border-radius:5px;
    justify-content:space-between;
    align-items: center;
    background: rgb(14,14,14);
    box-shadow: 0px 2px 5px black;
    border: 1px solid  #5C23D1;
    margin:10px auto;
    max-width: 280px;
    min-width: 280px;
}

#Game .token-exchange form input {
    border:0px;
    padding:5px;
    flex:1;
    font-size: 1.5rem;
    background:transparent;
    color:#5C23D1;
    font-weight:bold;
    display: flex;
    width: 100%;
}

#Game form input:focus {
    outline:none;
}

#Game .token-exchange form button {
    border:0px;
    padding:15px;
    background: #5C23D1;
    color:white;
    font-weight: bold;
    text-transform:uppercase;
    border-radius: 5px;
    margin:10px;
    display: block;
    cursor:pointer;
    box-shadow: 0px 2px 4px black;
    width:100px;
}

/* create game */
#Game .create-game {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex:1;
    border:1px solid #5d23d17f;
    border-radius: 5px;
    background: rgb(14,14,14);
    box-shadow: 0px 2px 5px black;
    margin: auto;
    padding: 10px;
    max-width: 550px;
}

#Game .create-game form {
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-wrap: wrap;
}

#Game .token-exchange .game-input {
    padding: 5px;
    display: flex;
    justify-self: center;
    align-items: center;
}

#Game .create-game .inputs {
    display: flex;
    border-radius:5px;
    justify-content:space-between;
    align-items: center;
    background: rgb(14,14,14);
    box-shadow: 0px 2px 5px black;
    border: 1px solid  #5C23D1;
    margin:10px auto;
}

#Game .create-game .game-input {
    display: flex;
    padding: 5px;   
}

#Game form svg {
    width:30px;
    height:30px;
}

#Game .create-game form input {
    border:0px;
    padding:5px;
    flex:1;
    font-size: 1.5rem;
    width:120px;
    background:transparent;
    color:#5C23D1;
    font-weight:bold;
    display: flex;
    border-radius:5px;
    justify-content:space-between;
    align-items: center;
    background: rgb(14,14,14);
    box-shadow: 0px 2px 5px black;
}

#Game .create-game .buttons {
    flex: 1;
    min-width: 220px;
}

#Game .create-game .buttons button {
    flex:1px;
    border:0px;
    padding:5px;
    margin: 5px;
    background: #5d23d17f;
    width:80px;
    height:80px;
    border-radius: 50%;
    font-weight: bold;
    color:rgba(0, 0, 0, 0.501);
    cursor:pointer;
    box-shadow: 0px 2px 4px black;
}

#Game .game .player-data .moon,
#Game .game .player-data .blood-moon {
    display: block;
    border:0px;
    padding:5px;
    margin: 5px;
    color:white;
    background: #5d23d17f;
    width:50px;
    height:50px;
    border-radius: 50%;
}

#Game .create-game .buttons button.moon {
    background:linear-gradient(to top left, rgba(255, 255, 255, 0.2), rgba(128, 128, 128, 0.2));
}

#Game .create-game .buttons button.blood-moon {
    background:linear-gradient(to top left, rgba(255, 0, 0, 0.2), rgba(139, 0, 0, 0.2));
}

#Game .create-game .buttons button.moon.active,
#Game .game .player-data .moon {
    background:linear-gradient(to top left, white, rgb(47, 47, 47));
    box-shadow:0px 0px 20px white;
}

#Game .create-game .buttons button.blood-moon.active,
#Game .game .player-data .blood-moon {
    background:linear-gradient(to top left, red, rgb(102, 0, 0));
    box-shadow:0px 0px 20px red;
}

#create-game {
    border:0px;
    padding:15px;
    background: #5C23D1;
    color:white;
    font-weight: bold;
    text-transform:uppercase;
    border-radius: 5px;
    margin:10px;
    display: block;
    cursor:pointer;
    box-shadow: 0px 2px 4px black;
}

#Game .game-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    margin: 5px auto;
    max-width: 800px;
}

#Game .game-filters button {
    flex:1;
    padding:10px;
    margin:5px;
    color:white;
    border: 0px;
    border-radius: 5px;
    font-size: 1.2rem;
    background: #5d23d16a;
    text-transform:uppercase;
    font-weight: bold;
    box-shadow: 0px 2px 4px black;
    cursor:pointer;
    min-width: 150px;
}

#Game .game-filters button.active {
    background: #5C23D1;
    color:white;
}

#Game .game-filters select {
    flex:1;
    padding:10px;
    margin:5px;
    color:white;
    border: 0px;
    border-radius: 5px;
    font-size: 1.2rem;
    background: #5C23D1;
    text-transform:uppercase;
    font-weight: bold;
    box-shadow: 0px 2px 4px black;
    text-align: center;
    cursor:pointer;
    appearance: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}

#Game .games {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#Game .game {
    width:300px;
    background:rgb(14,14,14);
    margin:10px;
    border-radius: 10px;
    overflow: hidden;
    padding:10px;
    box-shadow:0px 2px 5px black;
    border:1px solid #5d23d17a;
    background:rgb(14,14,14);
}

#Game .game .game-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color:#5C23D1;
    align-items: center;
    border:1px solid #5C23D1;
    margin:5px;
    border-radius: 5px;
}

#Game .game .game-header h3 {
    font-weight:bold;
    font-size: 2rem;
    color:#ccc8d5;
}

#Game .game .game-header svg {
    cursor: pointer;
}

#Game .game.finished {
    background:gray;
}

#Game .game h4 {
    font-size: 2rem;
}

#Game .game .player-container {
    width:100%;
    display: flex;
    justify-content: center;
    align-items:stretch;
}

#Game .game .player {
    padding: 10px;
    border-radius: 5px;
    flex:1;
    border:1px solid #252525;
    margin:5px;
}

#Game .game .player.winner {
    border:1px solid green;
    background:rgba(0, 255, 0, 0.155);
}

#Game .game.closed,
#Game .game .player.loser {
    opacity: 0.2;
}

#Game .game .player.empty {
    border:1px solid #5C23D1;
    background:#5d23d16a;
}

#Game .game .player-data {
    padding:0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#Game .game .player-data p {
    font-weight:bold;
    text-transform: uppercase;
    padding:10px;
    border:1px solid rgb(220, 220, 220);
    margin:5px;
    border-radius:5px;
    box-shadow:0px 2px 5px rgba(0, 0, 0, 0.45);
}

#Game .game .player img {
    width:100%;
    border-radius:50%;
    width:80px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    margin:5px;
}

#Game .game .player button {
    border:0px;
    width:100%;
    border-radius: 5px;
    font-size: 1rem;
    padding:10px;
    background: #5C23D1;
    color:white;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow:0px 2px 5px rgba(0, 0, 0, 0.45);
    cursor:pointer;
}

#Game .game.closed .player button {
    background: transparent;
    color:#5C23D1;
}

#Game .game .bet {
    flex:2;
    border-radius: 5px;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid rgb(40, 40, 40);
    border-radius: 5px;
    margin:5px;
    color:#5C23D1;
    border:1px solid #5C23D1;
}

#Game .game .bet svg {
    width:25px;
    height:25px;
}