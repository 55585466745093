/* Modal */
#modal {
    position: fixed;
    background:rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

#modal .game-modal,
#modal .info-modal {
    position: fixed;
    background:rgb(14,14,14);
    overflow-y: auto;
    width:100%;
    max-height: calc(100vh - 50px);
    max-width: 800px;
    text-align: center;
    box-shadow: 0px 2px 15px rgb(0, 0, 0);
    border:1px solid #5d23d17a;
    border-radius: 5px;
    padding:10px;
}

#modal .game-modal .game-header,
#modal .info-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color:#ccc8d5;
    align-items: center;
    border:1px solid #5C23D1;
    margin:5px;
    border-radius: 5px;
    margin-bottom: 15px;
}

#modal .info-container {
    padding: 10px;
    color:#ccc8d5;
    border:1px solid #5C23D1;
    margin:5px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: left;
}

#modal .info-container a {
    color:#ccc8d5;
}

#modal .info-container h4 {
    font-size:2rem;
    padding: 10px;
}

#modal .info-container p {
    padding: 10px;
}

#modal .game-modal .game-header h3,
#modal .info-header h3 {
    font-weight:bold;
    font-size: 2.5rem;
}

#close-game-modal {
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
    color: #5C23D1;
    border:2px solid #5C23D1;
    padding: 5px;
    width:40px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

#modal .game-modal .game-timeline {
    display: flex;
    justify-content: space-between;
    color:#5C23D1;
    align-items: center;
    border:1px solid #5C23D1;
    margin:5px;
    border-radius: 5px;
    text-align: left;
    margin-bottom:15px;
}

#modal .game-modal .game-timeline .moon,
#modal .game-modal .game-timeline .blood-moon {
    display: inline-block;
    border:0px;
    padding:5px;
    margin: 5px;
    color:white;
    background: #5d23d17f;
    width:30px;
    height:30px;
    border-radius: 50%;
}

#modal .info-container .buttons {
    display: flex;
    max-width: 180px;
}

#modal .info-container .moon,
#modal .info-container .blood-moon {
    display: flex;
    justify-content: center;
    align-items: center;
    border:0px;
    padding:5px;
    margin: 5px;
    color:rgba(0, 0, 0, 0.501);
    background: #5d23d17f;
    width:80px;
    height:80px;
    border-radius: 50%;
    text-align: center;
    flex:1;
    cursor: auto;
    font-weight: bold;
}

#modal .game-modal .game-timeline tr td .container {
    display: flex;
    align-items:center;
    justify-content:space-between;
}

#modal .game-modal .game-timeline .moon,
#modal .info-container .moon {
    background:linear-gradient(to top left, white, rgb(47, 47, 47));
    box-shadow:0px 0px 5px white;
}

#modal .game-modal .game-timeline .blood-moon,
#modal .info-container .blood-moon {
    background:linear-gradient(to top left, red, rgb(102, 0, 0));
    box-shadow:0px 0px 5px red;
}

#modal .game-modal .game-timeline table {
    border-collapse: collapse;
    width: 100%;
}

#modal .game-modal .game-timeline tr {
    font-size: 1.5rem;
    padding: 10px;
    border-bottom: 1px solid #5d23d16a;
}

#modal .game-modal .game-timeline tbody tr:last-of-type {
    border-bottom: unset;
}

#modal .game-modal .game-timeline th,
#modal .game-modal .game-timeline td {
    padding: 10px;
    color:#ccc8d5;
}

#modal .game-modal .bet {
    flex:2;
    border-radius: 5px;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:2px solid rgb(40, 40, 40);
    border-radius: 5px;
    margin:5px;
    color:#5C23D1;
    border:1px solid #5C23D1;
    font-size: 2rem;
}

#modal .game-modal .bet svg {
    width:25px;
    height:25px;
}