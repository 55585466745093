.lookup {
    padding-top:60px;
    margin: 0 auto;
    min-height: 100vh;
    border-bottom: 1px solid rgb(40, 40, 40);
}

.lookup h2 {
    padding:20px;
    font-size:3rem;
    text-shadow: 0px 2px 5px black;
}

.lookup .lookup-container {
    margin: 0 auto;
    width:100%;
    text-align: center;
    padding:20px;
}

.lookup form {
    display: flex;
    margin:10px auto;
    border-radius: 10px;
    max-width: 300px;
    background:rgb(14, 14, 14);
}

.lookup .flex {
    flex:4;
    display: flex;
    background:rgb(255, 255, 255);
    padding:5px;
    border-radius: 5px;
}

.lookup form label,
.lookup form input,
.lookup form button {
    display: block;
    width:100%;
    font-size:1.5rem;
    font-weight: bold;
}

.lookup form label {
    padding:15px;
    font-size: 1.5rem;
    flex:2;
}

.lookup form input {
    padding:5px;
    text-align: center;
    border:0px;
    flex:3;
}

.lookup form button {
    background:rgb(190, 0, 0);
    color:white;
    border:0px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 5px;
    width:50px;
    padding:5px;
    flex:1;
}

.lookup form button svg {
    vertical-align: middle;
    font-size:0.5rem;
    width:30px;
    height:30px
}

.lookup form button:hover {
    background:rgb(47, 1, 1);
}

.lookup .fetchError {
    font-size: 1.5rem;
    margin:0px auto;
    margin-top: 30px;
    padding:20px;
    max-width: 700px;
}

.lookup .nfts {
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content:center;
    margin:0px;
    cursor: pointer;
    padding:20px;
}

.lookup .nft {
    flex:1;
    margin:10px;
    text-align: center;
    min-width: 350px;
    max-width:350px;
    box-shadow:0px 5px 10px rgb(0, 0, 0);
    background:rgb(0, 0, 0);
    border-radius: 10px;
    overflow: hidden;
}

.lookup .img-container {
    display: block;
    overflow: hidden;
}

.lookup .nft .image-container {
    display: block;
    position: relative;
}

.lookup .nft .image-container p {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 5px 5px 10px black;
    font-style: italic;
}

.lookup .nft-img {
    display: block;
    width: 350px;
    height:350px;
    transition:all ease .4s;
    position: relative;
    max-height: 100%;
    object-fit: contain;
}

.lookup .img-container:hover .nft-img {
    transform: scale(1.1);
}

.lookup .nft .nft-footer {
    padding:5px;
    font-size: 2rem;
    margin: 0px auto;
    vertical-align: top;
    display: flex;
    justify-content: center;
    background:rgb(14, 14, 14);
}

.lookup .nft .nft-footer .links {
    flex:1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    font-size: 2rem;
    margin:5px;
}

.lookup .nft h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    border-radius: 10px;
    font-size: 2rem;
    margin:5px;
    /* border: 2px solid rgb(0, 0, 0); */
}

.lookup .nft .nft-footer .links a {
    margin:5px;
    cursor: pointer;
    flex:1;
}

.lookup .nft .nft-footer svg {
    width:28px;
    height:28px;
}

.lookup-table-container {
    margin:20px;
    background:rgb(14, 14, 14);
    box-shadow: 0px 2px 5px rgb(0, 0, 0);
    padding:20px;
    border-radius: 5px;
}